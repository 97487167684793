/* eslint-disable max-statements */
/* eslint-disable max-lines */
import useStripeMethods from '@/core/stripe/useStripeMethods';
import { useTracker } from '@/core/tracking';
import warningInfo from '@/public/images/icons/warning_info.svg';
import { useLocalization } from '@/services/localizationService';
import {
  PaymentPlatform,
  PaymentStrategiesIds,
} from '@/services/paymentService';
import {
  isBusySelector,
  lockUISelector,
  unlockUISelector,
} from '@/stores/appStore';
import { closeModalSelector, pushModalSelector } from '@/stores/modalStore';
import settingsState from '@/stores/settingsStore';
import Spinner from '@/ui/content/Spinner';
import { Form } from '@/ui/form';
import * as Sentry from '@sentry/nextjs';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  trackCompletedEvent,
  trackEvent,
  trackFailedEvent,
  trackStartedEvent,
} from '@/core/monitoring/trackEvents';
import { useReCaptcha } from '@/core/recaptcha/useReCaptcha';
import { useConsent } from '@/features/userConsent';
import {
  useMediaService,
  usePaymentService,
  usePreferencesService,
} from '@/layout/appWrapper/ServiceProvider';
import { Price } from '@/services/priceService';
import { TaskResult } from '@/services/uploaderService';
import { useUser } from '@/services/userService';
import {
  onboardingState,
  showFreeTrialSelector,
} from '@/stores/onboardingStore';
import CountryNotAllowedModal from '@/features/payment/modals/CountryNotAllowedModal';
import useDownload from '../downloadMedia/downloadSidebar/useDownload';
import maybeShowSuccessToast from './maybeShowSuccessToast';
import { ErrorModal } from './modals';

interface Props {
  selectedPrice: Price;
  onSubmit: CallableFunction;
  willDownloadAutomatically: boolean;
  isIntroPriceActive?: boolean;
}

export default function PaymentInformationForm({
  selectedPrice,
  onSubmit,
  willDownloadAutomatically,
  isIntroPriceActive = false,
}: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const lockUI = useSetRecoilState(lockUISelector);
  const unlockUI = useSetRecoilState(unlockUISelector);
  const isBusy = useRecoilValue(isBusySelector);
  const { catchStripePriceError, trackPayment } = usePaymentService();

  const router = useRouter();
  const { taskId } = router.query;

  const pushModal = useSetRecoilState(pushModalSelector);
  const closeModal = useSetRecoilState(closeModalSelector);
  const { storeNewPreferences } = usePreferencesService();

  const { data: user, mutate: mutateUser } = useUser();

  const { useMedia } = useMediaService();
  const { getMediaAndUpdateStore } = useMedia();
  const { download } = useDownload();

  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const { track } = useTracker();
  const { t, data } = useLocalization();
  const { isReminderEnabled, selectedPaymentStrategy } =
    useRecoilValue(onboardingState);

  const shouldShowFreeTrial = useRecoilValue(showFreeTrialSelector);

  const [isPaymentButtonEnabled, setIsPaymentButtonEnabled] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('card');

  const settings = useRecoilValue(settingsState);

  const { shouldShowMarketingConsentModal } = useConsent();

  const { disableEmailReminder } = settings.values;

  useReCaptcha();

  const overrideEmailReminder = useCallback(async () => {
    if (!user || settings.values.oneTimePayment) {
      return;
    }

    if (disableEmailReminder) {
      await storeNewPreferences({
        ...user.preferences,
        wantsEmailReminder: false,
      });
      return;
    }

    await storeNewPreferences({
      ...user?.preferences,
      wantsEmailReminder: isReminderEnabled,
    });
  }, [
    user,
    settings.values.oneTimePayment,
    disableEmailReminder,
    isReminderEnabled,
    storeNewPreferences,
  ]);

  const { subscribe: stripeSubscribe, directPayment: stripeDirectPayment } =
    useStripeMethods(
      stripe!,
      mutateUser,
      (settings.values.isFreeTrialEnabled && shouldShowFreeTrial) || false,
      isIntroPriceActive,
      selectedPrice?.id,
      selectedPrice.exports
    );

  const onStripeSubmit = useCallback(async () => {
    catchStripePriceError(stripe, elements, selectedPrice?.id);
    if (!stripe || !elements) {
      return;
    }

    await overrideEmailReminder();
    lockUI({});

    const payTrackingInfo = {
      paymentPlatform: selectedPaymentMethod,
      paymentStrategy:
        selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime
          ? 'oneTime'
          : 'subscription',
    };

    try {
      setIsLoadingPayment(true);
      trackPayment({
        track,
        status: 'selected',
        paymentPlatform: selectedPaymentMethod as PaymentPlatform,
        priceId: selectedPrice?.id,
        freeTrialEnabled: shouldShowFreeTrial,
        emailReminderEnabled: isReminderEnabled,
        paymentStrategy:
          selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime
            ? 'oneTime'
            : 'subscription',
      });

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw new Error(submitError.message);
      }

      trackStartedEvent(['payment'], payTrackingInfo);
      let showSuccessToast = true;

      if (selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime) {
        // Wanted code duplication
        try {
          await stripeDirectPayment({ elements });
          trackCompletedEvent(['payment'], payTrackingInfo);
        } catch (e) {
          trackFailedEvent(['payment'], {
            ...payTrackingInfo,
            error: e.toString(),
          });
          throw e;
        }
        if (taskId) {
          const newMedia = await getMediaAndUpdateStore();
          await download({
            downloadUrl: (newMedia as TaskResult).outputUrl,
          });
        }
      } else {
        try {
          const status = await stripeSubscribe({
            elements,
            isEmailReminderEnabled: isReminderEnabled,
            paymentStrategyId: selectedPaymentStrategy?.id || 'base',
            willDownloadAutomatically,
          });

          if (status === 'incomplete') {
            showSuccessToast = false;
            pushModal({
              id: 'creationError',
              Modal: ErrorModal,
              error: {
                message: t(data.account.incompleteSubscriptionCreatedMessage),
              },
            });
          }
          trackCompletedEvent(['payment'], payTrackingInfo);
        } catch (e) {
          trackFailedEvent(['payment'], {
            ...payTrackingInfo,
            error: e.toString(),
          });
          throw e;
        }
      }

      closeModal('payment');

      trackPayment({
        track,
        status: 'completed',
        paymentPlatform: selectedPaymentMethod as PaymentPlatform,
        priceId: selectedPrice?.id,
        freeTrialEnabled: shouldShowFreeTrial,
        emailReminderEnabled: isReminderEnabled,
        paymentStrategy:
          selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime
            ? 'oneTime'
            : 'subscription',
      });

      await mutateUser();

      if (taskId) {
        await getMediaAndUpdateStore();
      }
      if (showSuccessToast) {
        maybeShowSuccessToast({
          selectedPaymentStrategy,
          willDownloadAutomatically,
          settings,
          pushModal,
          shouldShowMarketingConsentModal,
          message:
            selectedPaymentStrategy?.id === PaymentStrategiesIds.OneTime
              ? data.result.oneTimePaymentSuccess
              : data.onboarding.congratsMember,
        });
      }
      onSubmit();
    } catch (e) {
      Sentry.captureException(e);

      if (e.status === 403 && e.message.startsWith('Web subscriptions')) {
        pushModal({
          id: 'forbiddenCountryError',
          Modal: CountryNotAllowedModal,
        });
      } else {
        pushModal({ id: 'paymentError', Modal: ErrorModal, error: e });
      }
    }
    setIsLoadingPayment(false);
    unlockUI({});
  }, [
    catchStripePriceError,
    stripe,
    elements,
    selectedPrice?.id,
    overrideEmailReminder,
    lockUI,
    selectedPaymentMethod,
    selectedPaymentStrategy,
    unlockUI,
    trackPayment,
    track,
    shouldShowFreeTrial,
    isReminderEnabled,
    closeModal,
    mutateUser,
    taskId,
    willDownloadAutomatically,
    settings,
    pushModal,
    shouldShowMarketingConsentModal,
    data.result.oneTimePaymentSuccess,
    data.onboarding.congratsMember,
    data.account.incompleteSubscriptionCreatedMessage,
    onSubmit,
    stripeDirectPayment,
    getMediaAndUpdateStore,
    download,
    stripeSubscribe,
    t,
  ]);

  const onElementChange = useCallback((event) => {
    setIsPaymentButtonEnabled(!!event.complete);
    setSelectedPaymentMethod(event.value.type);
  }, []);

  useEffect(() => {
    if (!selectedPrice) {
      return;
    }

    trackEvent(['paywall', 'shown'], {
      type: 'payment_step',
    });
  }, [selectedPrice]);

  if (!selectedPrice) {
    return (
      <div className="flex justify-center items-center">
        <TailSpin color="black" height="50" width="50" aria-label="loading" />
      </div>
    );
  }

  const stripeButtonText = shouldShowFreeTrial
    ? data.onboarding.startForFree
    : selectedPaymentStrategy &&
      selectedPaymentStrategy.id === PaymentStrategiesIds.OneTime
    ? willDownloadAutomatically
      ? data.onboarding.proceedToDownload
      : data.common.letsGo
    : isIntroPriceActive
    ? data.common.letsGo
    : data.common.subscribe;

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
    },
  };

  return (
    <Form className="space-y-7" onSubmit={onStripeSubmit} disabled={isBusy}>
      <div className="space-y-4 pb-10">
        <PaymentElement
          className="rounded-xl M18 mt-2"
          onChange={onElementChange}
          options={paymentElementOptions}
        />
        {shouldShowFreeTrial ? (
          <div className="text-overlay-black-60 text-xs inline-flex">
            <img {...warningInfo} alt="Warning info" />
            <span className="M12 ps-1.5">
              {t(data.onboarding.enterCardInfo)}
            </span>
          </div>
        ) : null}
        <p className={`R11 text-gray-300 `}>
          This site is protected by reCAPTCHA and the Google{' '}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{' '}
          apply.
        </p>
        {selectedPaymentStrategy?.id === PaymentStrategiesIds.Business && (
          <p className="R11 text-gray-300 mt-3">
            {t(data.common.acceptTosAndPrivacyPolicy1)}{' '}
            <a
              href="/docs/remini-business-additional-terms.pdf"
              target="_blank"
            >
              <u>{t(data.common.additionalTerms)}</u>
            </a>
            .
          </p>
        )}

        <Form.Button
          className="w-full btn btn--xl shadow-dark-button btn--black"
          disabled={!isPaymentButtonEnabled}
        >
          <p className="SB18">
            {t(stripeButtonText)}
            {stripeButtonText === data.common.letsGo ? '!' : ''}
          </p>

          {isLoadingPayment && <Spinner color="white" size={20} />}
        </Form.Button>
      </div>
    </Form>
  );
}
