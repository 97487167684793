import creditIcon from '@/public/images/icons/credit.svg';
import diamondPurpleIcon from '@/public/images/icons/diamond-purple.svg';
import diamondRedIcon from '@/public/images/icons/diamond-red.svg';
import { useEnhanceLimitService } from '@/services/enhanceLimitService/useEnhanceLimitService';
import { useLocalization } from '@/services/localizationService';
import {
  PaymentStrategiesIds,
  PaymentStrategy,
} from '@/services/paymentService';
import { Interval, PricePeriod } from '@/services/priceService';
import { PaymentStrategies } from '@/services/settingsService';
import settingsState from '@/stores/settingsStore';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

interface Props {
  selectedPeriod?: PricePeriod;
  remotePaymentStrategies: PaymentStrategies;
  isUpgrade?: boolean;
  userPricePeriod?: PricePeriod;
}

export default function usePaymentStrategies({
  selectedPeriod,
  remotePaymentStrategies,
  isUpgrade,
  userPricePeriod,
}: Props) {
  const { t, data } = useLocalization();
  const { getSubscriptionPlanLimits } = useEnhanceLimitService();
  const settings = useRecoilValue(settingsState);

  // TODO adapt to limits on other plans if necessary
  const { personalPlanLimit, businessPlanLimit, period } = useMemo(() => {
    let periodToUse = selectedPeriod;
    if (isUpgrade && userPricePeriod) {
      periodToUse = userPricePeriod;
    }
    let period: Interval | undefined;
    switch (periodToUse) {
      case 'weekly':
        period = 'week';
        break;
      case 'monthly':
        period = 'month';
        break;
      case 'yearly':
      default:
        period = 'year';
        break;
    }
    const personalPlanLimit = getSubscriptionPlanLimits(
      PaymentStrategiesIds.Personal,
      period
    );
    const businessPlanLimit = getSubscriptionPlanLimits(
      PaymentStrategiesIds.Business,
      period
    );
    return {
      personalPlanLimit,
      businessPlanLimit,
      period,
    } as const;
  }, [
    getSubscriptionPlanLimits,
    isUpgrade,
    remotePaymentStrategies,
    selectedPeriod,
    userPricePeriod,
  ]);

  const paymentStrategies: PaymentStrategy[] = useMemo(
    () => [
      {
        id: PaymentStrategiesIds.OneTime,
        title: t(data.common.payAsYouGo),
        titleIcon: creditIcon,
        subtitle: t(data.common.otpDownloadPackSubtitle),
        features: [
          { text: t(data.tiers.featAllAiModels), check: true },
          { text: t(data.tiers.downloadWithoutWatermark), check: true },
          { text: t(data.tiers.featOutputPersonal), check: true },
        ],
        exports: 20,
      },
      {
        id: PaymentStrategiesIds.Personal,
        title: t(data.tiers.personal),
        titleIcon: diamondRedIcon,
        subtitle: t(data.tiers.personalSubtitle),
        features: [
          {
            text: t(data.tiers.featLimitPersonal, {
              n: personalPlanLimit.exports ?? 0,
              period: t(data.common[period]),
            }),
            check: true,
          },
          { text: t(data.tiers.featAllAiModels), check: true },
          { text: t(data.tiers.featOutputPersonal), check: true },
          {
            text: t(data.tiers.individualVideoLimits, {
              mediaSize: personalPlanLimit.videoLimits.maxSizeMb,
            }),
            check: true,
          },
          {
            text: t(data.tiers.cumulativeVideoLimits, {
              mediaSize: personalPlanLimit.videoLimits.maxPeriodSizeMb,
            }),
            check: true,
          },
          { text: t(data.tiers.featVideoEnhancePersonal), check: true },
          { text: t(data.tiers.downloadWithoutWatermark), check: true },
          { text: t(data.tiers.featCancelPersonal), check: true },
          { text: t(data.tiers.featBulkUpload) },
          { text: t(data.tiers.featCommercialBusiness) },
          ...(settings.values.psPlugin
            ? [{ text: t(data.account.photoshopPlugin) }]
            : []),
          { text: t(data.tiers.featAccessMobile) },
        ],
      },
      {
        id: PaymentStrategiesIds.Business,
        title: t(data.tiers.business),
        titleIcon: diamondPurpleIcon,
        subtitle: t(data.tiers.businessSubtitle),
        features: [
          { text: t(data.tiers.featLimitBusiness), check: true },
          { text: t(data.tiers.featAllAiModels), check: true },
          { text: t(data.tiers.featOutputBusiness), check: true },
          {
            text: t(data.tiers.individualVideoLimits, {
              mediaSize: businessPlanLimit.videoLimits.maxSizeMb,
            }),
            check: true,
          },
          {
            text: t(data.tiers.cumulativeVideoLimits, {
              mediaSize: businessPlanLimit.videoLimits.maxPeriodSizeMb,
            }),
            check: true,
          },
          { text: t(data.tiers.featVideoEnhancePersonal), check: true },
          { text: t(data.tiers.noWatermark), check: true },
          { text: t(data.tiers.featCancelPersonal), check: true },
          { text: t(data.tiers.featBulkUpload), check: true },
          { text: t(data.tiers.featCommercialBusiness), check: true },
          ...(settings.values.psPlugin
            ? [{ text: t(data.account.photoshopPlugin), check: true }]
            : []),
          { text: t(data.tiers.featAccessMobile) },
        ],
      },
    ],
    [
      businessPlanLimit.videoLimits.maxPeriodSizeMb,
      businessPlanLimit.videoLimits.maxSizeMb,
      data.account.photoshopPlugin,
      data.common,
      data.tiers.business,
      data.tiers.businessSubtitle,
      data.tiers.cumulativeVideoLimits,
      data.tiers.downloadWithoutWatermark,
      data.tiers.featAllAiModels,
      data.tiers.featBulkUpload,
      data.tiers.featCancelPersonal,
      data.tiers.featCommercialBusiness,
      data.tiers.featLimitBusiness,
      data.tiers.featLimitPersonal,
      data.tiers.featOutputBusiness,
      data.tiers.featOutputPersonal,
      data.tiers.featVideoEnhancePersonal,
      data.tiers.individualVideoLimits,
      data.tiers.noWatermark,
      data.tiers.personal,
      data.tiers.personalSubtitle,
      period,
      personalPlanLimit.exports,
      personalPlanLimit.videoLimits.maxPeriodSizeMb,
      personalPlanLimit.videoLimits.maxSizeMb,
      settings.values.psPlugin,
      t,
    ]
  );

  return paymentStrategies;
}
