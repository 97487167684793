import { useCallback } from 'react';
import { OAuthProvider, linkWithCredential, Auth } from 'firebase/auth';
import { AccountLinkingFailedModal } from './modals';

const useLogin = () => {
  const performLogin = useCallback(
    async (
      auth: Auth,
      pushModal: (config) => void,
      setGlobalParams: (params) => void,
      track: (category, action, params) => void,
      providerName: string | null,
      resetProviderName: () => void,
      login: (id) => void
    ) => {
      if (!auth.currentUser) {
        return;
      }

      const authPendingCredential = window.sessionStorage.getItem(
        'auth:pendingCredential'
      );

      if (authPendingCredential) {
        try {
          await linkWithCredential(
            auth.currentUser,
            OAuthProvider.credentialFromJSON(authPendingCredential)
          );
        } catch (error) {
          // not clear, why we should handle error here?
          if (error.code === 'auth/email-already-in-use') {
            pushModal({
              id: 'account-linking-failed',
              Modal: AccountLinkingFailedModal,
            });
          }
          // propagate error here. Not only catch it otherwise others will just see the login as done, even if it is not.
          throw error;
        } finally {
          window.sessionStorage.removeItem('auth:pendingCredential');
        }
      }

      const idToken = await auth.currentUser.getIdToken(true);
      await login(idToken);

      setGlobalParams((params: { [key: string]: string }) => ({
        ...params,
        email: auth.currentUser?.email,
      }));

      track('login', 'completed', {
        actionValue: providerName,
        email: auth.currentUser.email,
      });

      resetProviderName();
    },
    []
  );
  return {
    performLogin,
  };
};

export default useLogin;
