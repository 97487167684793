import { v4 as uuidv4 } from 'uuid';
import { createCookie, readCookie } from '@/core/tracking/pico/helpers';
import { safeLocalStorage } from '@/core/safeLocalStorage/safeLocalStorage';

export type IDProvider = {
  setUserID: (id: string) => void;
  setFirebaseID: (id: string) => void;
  getFirebaseID: () => string;
  getUserID: () => string | undefined;
  setBackendID: (id: string) => string;
  getBackendID: () => string;
  getLocalStorageID: () => string;
  getClientID: () => string;
};

const CLIENT_ID_KEY = '_pico_cid';
const LOCAL_STORAGE_ID_KEY = '_pico_lsid';

export const createIDProvider = (userId?: string): IDProvider => {
  let _userId = userId;
  let _firebaseId: string;
  let _backendId: string;

  const createClientID = () => {
    const clientID = uuidv4();
    createCookie(CLIENT_ID_KEY, clientID);
    safeLocalStorage.setItem(CLIENT_ID_KEY, clientID);
    return clientID;
  };

  const createLocalStorageID = () => {
    const localStorageId = uuidv4();
    safeLocalStorage.setItem(LOCAL_STORAGE_ID_KEY, localStorageId);
    return localStorageId;
  };

  return {
    setUserID: (id: string) => {
      _userId = id;
    },
    setFirebaseID: (id: string) => {
      _firebaseId = id;
    },
    getFirebaseID: () => _firebaseId,
    setBackendID: (id: string) => {
      _backendId = id;
      return _backendId;
    },
    getBackendID: () => _backendId,
    getUserID: () => _userId,
    getLocalStorageID: () => {
      const fromLocalStorage = safeLocalStorage.getItem(LOCAL_STORAGE_ID_KEY);
      if (fromLocalStorage) return fromLocalStorage;
      return createLocalStorageID();
    },
    getClientID: () => {
      const fromCookie = readCookie(CLIENT_ID_KEY);
      const fromLocalStorage = safeLocalStorage.getItem(CLIENT_ID_KEY);
      if (fromCookie && fromLocalStorage && fromCookie === fromLocalStorage) {
        return fromCookie;
      }
      if (!fromCookie && fromLocalStorage) {
        createCookie(CLIENT_ID_KEY, fromLocalStorage);
        return fromLocalStorage;
      }
      if (!fromLocalStorage && fromCookie) {
        safeLocalStorage.setItem(CLIENT_ID_KEY, fromCookie);
        return fromCookie;
      }
      if (fromCookie !== fromLocalStorage) {
        createCookie(CLIENT_ID_KEY, fromLocalStorage);
        return fromLocalStorage;
      }
      return createClientID();
    },
  };
};
