import { useTracker } from '@/core/tracking';
import { ErrorModal } from '@/features/payment/modals';
import ExperimentsHandler from '@/features/settings/secretMenu/ExperimentsHandler';
import { useSettingsService } from '@/layout/appWrapper/ServiceProvider';
import caretLeft from '@/public/images/icons/caret-left.svg';
import { useUser } from '@/services/userService';
// import { isLoggedInSelector } from '@/stores/authStore';
import { pushModalSelector } from '@/stores/modalStore';
import { GenericModal } from '@/ui/content';
import Spinner from '@/ui/content/Spinner';
import { RightCaretIcon } from '@/ui/icons';
// import { Toggle } from '@/ui/toggle';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';

interface Props {
  id: string;
  [x: string]: any;
}

export default function SecretMenuModal({ id: modalId, ...rest }: Props) {
  const pushModal = useSetRecoilState(pushModalSelector);

  const { data: user } = useUser();
  const [isUserFree, setIsUserFree] = useState<boolean>(!!user?.isFree);
  console.log(isUserFree);

  // const isLoggedIn = useRecoilValue(isLoggedInSelector);
  const { checkSecretMenuPassword } = useSettingsService();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  // const [hasInteractedWithToggle, setHasInteractedWithToggle] =
  //   useState<boolean>(false);
  const [hasNavigatedToExperiments, setHasNavigatedToExperiments] =
    useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const { track } = useTracker();

  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    setIsUserFree(!!user?.isFree);
  }, [user?.isFree]);

  const checkPassword = useCallback(
    async (password?: string) => {
      if (typeof password === 'undefined') {
        return;
      }
      setIsFetching(true);

      try {
        await checkSecretMenuPassword(password);
        setPassword(password);
      } catch (error) {
        track('secret_menu', 'attempt_failed');

        pushModal({
          id: 'secretMenuError',
          Modal: ErrorModal,
          error: {
            message: 'The secret menu password is incorrect.',
          },
        });
      }

      setIsFetching(false);
    },
    [pushModal, track]
  );

  // const changeIsFree = useCallback(
  //   async (password: string, isFree: boolean) => {
  //     setIsFetching(true);
  //     setHasInteractedWithToggle(true);
  //
  //     await changeSubscriptionStatus(password, isFree);
  //     setIsUserFree(isFree);
  //
  //     setIsFetching(false);
  //   },
  //   []
  // );

  return (
    <GenericModal
      id={modalId}
      {...rest}
      className="w-full overflow-y-auto"
      closeIconClassName="text-black mb-0"
      showCloseIcon={!hasNavigatedToExperiments}
    >
      <div className="mx-7">
        <div className="w-full items-center pb-4">
          {hasNavigatedToExperiments ? (
            <>
              <div className="flex my-4">
                <button
                  type="button"
                  onClick={() => setHasNavigatedToExperiments(false)}
                >
                  <img {...caretLeft} className="rtl:scale-x-[-1]" alt="" />
                </button>
                <h3 className="SB20 text-black ps-4">Experiments management</h3>
              </div>
              <p className="R12 text-gray-500 mb-2">
                De-segmenting from an experiment means that you will see the
                experience of the control_segment AND that Orion will not try
                anymore to segment you in that experiment, unless you force a
                segmentation from the secret menu.
                <br />
                <br />
                This is useful when an experiment in Observing mode is in
                conflict with a Running one (keep in mind: if you de-segment
                from an Observing experiment, you will not see it anymore in
                this list).
                <br />
                <br />
                If you see that no alternative is selected for an experiment in
                the list, it means that—even if your user was compatible with
                that experiment—Orion could not segment you for a conflict.
                Please check on Janus&apos;s frontend to avoid any mistake.
              </p>
            </>
          ) : (
            <p className="SB20 text-black mb-2">Secret menu</p>
          )}
        </div>
        {isFetching ? (
          <div className="my-8 flex justify-center">
            <Spinner />
          </div>
        ) : password === '' ? (
          <div>
            <div className="mt-5 w-full bg-transparent border-2 border-black rounded-xl p-2 pe-4 flex justify-between items-center">
              <input
                ref={inputRef}
                className="flex-1 bg-transparent outline-none text-black w-full"
                type="password"
              />
            </div>
            <p className="mt-2 text-gray-400 italic R14">
              Insert the password to access the secret menu.
            </p>
            <div className="mt-5 flex justify-end items-center">
              <button
                className="btn btn--large btn--black"
                onClick={async () => checkPassword(inputRef.current?.value)}
              >
                Go!
              </button>
            </div>
          </div>
        ) : hasNavigatedToExperiments ? (
          <ExperimentsHandler modalId={modalId} password={password} />
        ) : (
          <div className="space-y-6">
            <div className="space-y-6">
              {/* TODO remove after experiment and change secret menu pass */}
              {/* {isLoggedIn && !user?.monetization.isSubscribed && ( */}
              {/*  <> */}
              {/*    <div className="M16 flex items-center justify-between"> */}
              {/*      <span> */}
              {/*        Change <i>isFree</i> status */}
              {/*      </span> */}
              {/*      <Toggle */}
              {/*        checked={isUserFree} */}
              {/*        onToggleChange={async (event) => { */}
              {/*          await changeIsFree(password, event.checked); */}
              {/*        }} */}
              {/*        brightColors */}
              {/*      /> */}
              {/*    </div> */}
              {/*    <hr /> */}
              {/*  </> */}
              {/* )} */}
              <div
                onClick={() => setHasNavigatedToExperiments(true)}
                className="M16 flex items-center justify-between cursor-pointer"
              >
                <span>Experiments management</span>
                <RightCaretIcon />
              </div>
              <hr />
            </div>
            {/* {hasInteractedWithToggle && ( */}
            {/*  <div className="mt-5 flex justify-end items-center"> */}
            {/*    <button */}
            {/*      className="btn btn--large btn--black" */}
            {/*      onClick={() => { */}
            {/*        mutateUser(); */}
            {/*        window.location.reload(); */}
            {/*      }} */}
            {/*    > */}
            {/*      Reload to see changes */}
            {/*    </button> */}
            {/*  </div> */}
            {/* )} */}
          </div>
        )}
      </div>
    </GenericModal>
  );
}
