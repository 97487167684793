import { ErrorBoundary } from '@/core/errorBoundary';
import { FirebaseProvider } from '@/core/firebase';
import { GoogleTagManagerScript } from '@/core/googleTagManager/GoogleTagManagerScript';
import { ModalRoot } from '@/core/modal';
import { PayPalProvider } from '@/core/paypal';
import { LoginProvider } from '@/features/account/login';
import { AnimatePresence } from 'framer-motion';
import { AppProps } from 'next/app';
import { FC, ReactElement, useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { SWRConfig } from 'swr';
import { fetcher } from '@/core/http';
import priceService from '@/services/priceService/priceService';
import redeemService from '@/services/redeemService';
import consentService from '@/services/consentService';
import captchaService from '@/services/captchaService';
import surveyService from '@/services/surveyService/surveyService';
import settingsService from '@/services/settingsService/settingsService';
import preferencesService from '@/services/preferenceService/preferencesService';
import formatService from '@/services/formatService/formatService';
import mediaService from '@/services/mediaService/mediaService';
import paymentService from '@/services/paymentService/paymentService';
import userService from '@/services/userService/userService';
import uploaderService from '@/services/uploaderService/uploaderService';
import shareService from '@/services/shareService/shareService';
import editorService from '@/services/editorService/editorService';
import authService from '@/services/authService/authService';
import tutorialService from '@/services/tutorialService/tutorialService';
import videoUsageService from '@/services/videoUsageService/videoUsageService';
import supportService from '@/services/supportService/supportService';
import { PayPalRedirectProvider } from '@/core/stripe/PayPalRedirectProvider';
import { useStripeProvider } from '@/core/stripe/useStripeProvider';
import { STRIPE_APPEARANCE } from '@/services/paymentService';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { OneTrustScript } from '@/core/cookies';
import ServiceProvider, { ProvidedServices } from './ServiceProvider';
import { useAppWrapper } from './useAppWrapper';

const RecoilRootPatched = RecoilRoot as FC<{ children: ReactElement }>;

/* Provided services definition */
const providedServices: ProvidedServices = {
  priceService,
  redeemService,
  consentService,
  captchaService,
  surveyService,
  settingsService,
  preferencesService,
  formatService,
  mediaService,
  paymentService,
  userService,
  uploaderService,
  shareService,
  editorService,
  authService,
  tutorialService,
  videoUsageService,
  supportService,
};

export function WithRecoilAndServices({
  Component,
  pageProps,
  router,
}: AppProps) {
  useAppWrapper();

  const { stripePromise } = useStripeProvider();
  const options: StripeElementsOptions = {
    mode: 'setup',
    currency: 'usd',
    amount: undefined,
  };

  useEffect(() => {
    const handleRouteChange = () => {
      function loadBanner() {
        if (
          !document.querySelector('.ot-sdk-show-settings') ||
          !window.OneTrust
        ) {
          return window.setTimeout(loadBanner, 100);
        }
        window.OneTrust.LoadBanner();
      }
      loadBanner();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <PayPalProvider>
      <FirebaseProvider>
        <LoginProvider>
          <Elements
            stripe={stripePromise}
            options={{
              ...options,
              appearance: STRIPE_APPEARANCE,
            }}
          >
            <PayPalRedirectProvider>
              <OneTrustScript />
              <GoogleTagManagerScript />
              <AnimatePresence>
                <Component key={router.route} {...pageProps} />
              </AnimatePresence>
              <ModalRoot />
            </PayPalRedirectProvider>
          </Elements>
        </LoginProvider>
      </FirebaseProvider>
    </PayPalProvider>
  );
}

export default function AppWrapper({ Component, pageProps, router }: AppProps) {
  return (
    <RecoilRootPatched>
      <ErrorBoundary>
        <SWRConfig value={{ fetcher }}>
          <ServiceProvider services={providedServices}>
            <WithRecoilAndServices
              Component={Component}
              pageProps={pageProps}
              router={router}
            />
          </ServiceProvider>
        </SWRConfig>
      </ErrorBoundary>
    </RecoilRootPatched>
  );
}
