import {
  createInternalConfiguration,
  PicoConfiguration,
} from '@/core/tracking/pico/config';
import {
  createNetworkAdapter,
  createNetworkClient,
} from '@/core/tracking/pico/network';
import {
  createEventMetadataProvider,
  createIDProvider,
  createPersistentInfoProvider,
  createUserMetadataProvider,
} from '@/core/tracking/pico/providers';
import { createSessionManager } from '@/core/tracking/pico/session';
import { createAttributionManager } from '@/core/tracking/pico/attribution';
import {
  createEventBuffer,
  createEventManager,
  createEventSender,
  EventInterceptor,
  EventManager,
  EventType,
  UserActionPicoEvent,
} from '@/core/tracking/pico/event';
import { createAnonymizationProxy } from './privacy/anonymizationProxy';
import { OneTrustAnalyticsConsentProvider } from './privacy/trackingConsentProvider';
import { createAnonymousInfoProvider } from './privacy/anonymousInfoProvider';
import { createAnonymousTimestampGenerator } from './privacy/anonymousTimestampGenerator';
import { TrackingSchemaActionInfoAnonymizer } from './privacy/actionInfoAnonymizer';

export type PicoUserAction = Omit<UserActionPicoEvent, 'type'>;

export interface PicoClient {
  activateAnonymization: () => void;
  addInterceptor: <T = any>(interceptor: EventInterceptor<T>, opts?: T) => void;
  trackUserAction: (a?: PicoUserAction) => void;
  setFirebaseID: (id?: string) => void;
  setUserID: (id?: string) => void;
  setBackendID: (id?: string) => void;
  setWebUserID: (id?: string) => void;
  setUserCreatedAt: (date?: Date) => void;
  getLocalStorageId: () => string;
  setSettings: (settings?: { [key: string]: any }) => void;
  setExperiments: (experiments?: { [key: string]: any }) => void;
  setIsFree: (isFree?: boolean) => void;
  start: () => void;
  stop: () => void;
  eventManager: EventManager;
}

const getPicoClient = (config: PicoConfiguration): PicoClient => {
  const internalConfig = createInternalConfiguration(config);
  const networkClient =
    config.networkClient ?? createNetworkClient(internalConfig);
  const idProvider = createIDProvider(config.userID);
  const sessionManager = config.sessionManager ?? createSessionManager();
  const eventMetadataProvider = createEventMetadataProvider({ sessionManager });
  const persistentInfoProvider =
    config.persistentInfoProvider ?? createPersistentInfoProvider();
  const attributionManager =
    config.attributionManager ??
    createAttributionManager({ persistentInfoProvider });
  const userMetadataProvider =
    config.userMetadataProvider ??
    createUserMetadataProvider({ attributionManager });

  const consentProvider =
    config.consentProvider ?? OneTrustAnalyticsConsentProvider;
  const timestampGenerator = createAnonymousTimestampGenerator();
  const anonymousInfoProvider = createAnonymousInfoProvider({
    timestampGenerator,
  });

  const actionInfoAnonymizer =
    config.actionInfoAnonymizer ?? TrackingSchemaActionInfoAnonymizer;

  const anonymizationProxy = createAnonymizationProxy({
    anonymousInfoProvider,
    consentProvider,
    actionInfoAnonymizer,
  });

  const networkAdapter = createNetworkAdapter({
    idProvider,
    config: internalConfig,
    userMetadataProvider,
    persistentInfoProvider,
    anonymizationProxy,
    consentProvider,
  });
  const eventSender = createEventSender({
    networkAdapter,
    networkClient,
    eventInterceptors: attributionManager.getAttributionInterceptors(),
  });
  const bufferDuration = config.bufferDuration ?? 1000;
  const eventBuffer = createEventBuffer({ eventSender, bufferDuration });
  const eventManager = createEventManager({
    eventMetadataProvider,
    sessionManager,
    eventBuffer,
  });

  let isStarted = false;
  return {
    activateAnonymization() {
      anonymizationProxy.setActive(true);
    },
    addInterceptor<T = any>(interceptor: EventInterceptor<T>, opts?: T) {
      eventSender.addInterceptor((e) => interceptor(e, opts, this));
    },
    trackUserAction(action: PicoUserAction): void {
      eventManager.trackEvent({ type: EventType.USER_ACTION, ...action });
    },

    setFirebaseID(firebaseId: string) {
      idProvider.setFirebaseID(firebaseId);
    },

    setBackendID(backendId: string) {
      idProvider.setBackendID(backendId);
    },

    setUserID(id: string) {
      idProvider.setUserID(id);
    },

    getLocalStorageId(): string {
      return idProvider.getLocalStorageID();
    },

    setWebUserID(id: string) {
      idProvider.setUserID(id);
    },
    setUserCreatedAt(date: Date) {
      userMetadataProvider.setCreatedAt(date);
    },
    setSettings(settings: { [key: string]: any }) {
      userMetadataProvider.setSettings(settings);
    },
    setExperiments(experiments: { [key: string]: any }) {
      userMetadataProvider.setExperiments(experiments);
    },
    setIsFree(isFree: boolean) {
      userMetadataProvider.setIsFree(isFree);
    },
    start() {
      if (isStarted) return;
      eventBuffer.startFlushing();
      isStarted = true;
    },
    stop() {
      eventBuffer.endFlushing();
      isStarted = false;
    },
    get eventManager() {
      return eventManager;
    },
  };
};

/**
 * Pico client constructor.
 * It returns a Pico instance with the configuration passed as argument.
 * Only if the js is executed inside the browser ( so only on client side )
 */
export default function Pico(config: PicoConfiguration): PicoClient {
  if (typeof window !== 'undefined') {
    return getPicoClient(config);
  }
  return {} as PicoClient;
}
