import { HttpService } from '@/core/http/httpService';
import { serviceSingletonGetter } from '@/services/serviceGetter';
import Router from 'next/router';
import {
  MaxFileQuantityForUpload,
  TaskStatus,
  TaskType,
} from './uploaderConstants';
import {
  BulkUpload,
  BulkUploadOptions,
  BulkUploadReprocessPayload,
  BulkUploadReprocessResponse,
  BulkUploadTask,
  ConvertedImage,
  defaultOutputForConversion,
  formatsNotSupportedInBrowsers,
  GetSharingPayload,
  ImageTaskSubmitPayload,
  PostBulkUpload,
  PostTask,
  PostVideoTaskResponse,
  Sharing,
  Task,
  TaskInfo,
  TaskMimeType,
  TaskOptions,
  TaskResult,
  VideoTaskMimeType,
  VideoTaskSubmitPayload,
} from './uploaderTypes';
import { useUploader } from './useUploader';

const VIDEO_TASK_BASE_ENDPOINT = '/v1/web/video-tasks';
const BULK_TASK_BASE_ENDPOINT = '/v1/web/tasks/bulk-upload';
const GEN_TASK_BASE_ENDPOINT = '/v1/web/tasks';

export const createUploaderService = ({
  get,
  post,
  deleteRequest,
  put,
}: HttpService) => {
  const postVideoTask = async (
    payload: VideoTaskSubmitPayload
  ): Promise<PostVideoTaskResponse> => post(VIDEO_TASK_BASE_ENDPOINT, payload);

  const postBulkUpload = async (payload: {
    inputTaskList: ImageTaskSubmitPayload[];
  }): Promise<PostBulkUpload> => post(BULK_TASK_BASE_ENDPOINT, payload);

  const isVideoTask = (file: File): boolean =>
    !!Object.values(VideoTaskMimeType).find(
      (videoType) => file.type === videoType
    );

  const isVideoTaskInFileList = (files: FileList): boolean =>
    !!Object.values(files).find((f) =>
      (Object.values(VideoTaskMimeType) as string[]).includes(f.type)
    );

  const isMaxMediaQuantityReached = (filesLength: number) =>
    filesLength > MaxFileQuantityForUpload;

  const processVideoTask = async (taskId) =>
    post(`${VIDEO_TASK_BASE_ENDPOINT}/${taskId}/process`);

  const processBulkUpload = async ({ bulkUploadId }) =>
    post(`${BULK_TASK_BASE_ENDPOINT}/${bulkUploadId}/process`);

  const reProcessBulkUpload = async ({
    bulkUploadId,
    bulkUploadReprocessPayload,
  }: {
    bulkUploadId: string;
    bulkUploadReprocessPayload: BulkUploadReprocessPayload;
  }): Promise<BulkUploadReprocessResponse> =>
    post(
      `${BULK_TASK_BASE_ENDPOINT}/${bulkUploadId}/reprocess`,
      bulkUploadReprocessPayload
    );

  const addMediasToBulkUpload = async ({
    bulkUploadId,
    inputTaskList,
  }: {
    bulkUploadId: string;
    inputTaskList: ImageTaskSubmitPayload[];
  }) =>
    post(`${BULK_TASK_BASE_ENDPOINT}/${bulkUploadId}`, {
      inputTaskList,
    });

  const getVideoTask = async (taskId: string): Promise<Task> =>
    get(`${VIDEO_TASK_BASE_ENDPOINT}/${taskId}`);

  const deleteTasks = async (taskIds: string[]) =>
    deleteRequest(`${GEN_TASK_BASE_ENDPOINT}/delete`, {
      taskIds,
    });

  const exportTask = async (taskId: string): Promise<string> => {
    const response: Task = await put(
      `${GEN_TASK_BASE_ENDPOINT}/${taskId}/export`
    );

    const { result } = response;
    const output = result.outputs[0];

    return output.url;
  };

  const getFinalOutputFromConvertedImages = (
    mimeType: string,
    convertedImages: ConvertedImage[]
  ): ConvertedImage['outputUri'] => {
    const convertedImage = convertedImages.find(
      ({ imageContentType }) => imageContentType === mimeType
    );

    if (!convertedImage) {
      throw new Error(`Output format not available: "${mimeType}"`);
    }

    return convertedImage.outputUri;
  };

  const getBulkUpload = async ({
    bulkUploadId,
  }: BulkUploadOptions): Promise<BulkUpload> =>
    get(`${BULK_TASK_BASE_ENDPOINT}/${bulkUploadId}`);

  const getTaskResult = async ({
    taskId,
    isVideo,
  }: TaskOptions): Promise<TaskResult | BulkUpload> => {
    if (isVideo) {
      const { result, status } = await getVideoTask(taskId);
      return {
        inputUrl: result ? result.inputUrl : '',
        outputUrl: result ? result.outputUrl : '',
        status,
      };
    }
    return getBulkUpload({
      bulkUploadId: taskId,
    });
  };

  const loadImage = (url: string) => {
    const image = new Image();
    const imagePromise = new Promise<HTMLImageElement>((resolve, reject) => {
      image.onload = () => resolve(image);
      image.onerror = () =>
        reject(new Error(`Image at url ${url} failed to load`));
    });
    image.crossOrigin = 'anonymous';
    image.src = url;
    return imagePromise;
  };

  const getSharing = async ({
    sharingId,
  }: GetSharingPayload): Promise<Sharing> =>
    get(`/v1/web/sharings/${sharingId}`);

  const getFirstCompletedTask = (
    bulk: BulkUpload
  ): {
    task: BulkUploadTask;
    index: number | undefined;
  } =>
    bulk.taskList.reduce(
      (acc, t, i) => {
        if (
          t.status === TaskStatus.Completed ||
          t.status === TaskStatus.Exported
        ) {
          return { task: t, index: i };
        }
        return acc;
      },
      { task: {} as BulkUploadTask, index: 0 }
    );

  const isTaskCompleted = (status: string, taskId: string): boolean => {
    if (status === TaskStatus.Failed) {
      throw new Error(`Task ${taskId} failed`);
    }
    return status === TaskStatus.Completed;
  };

  const cancelVideoTask = async (taskId: string) =>
    post(`${VIDEO_TASK_BASE_ENDPOINT}/${taskId}/cancel`);

  const setMediaTypeToUrl = (mediaType: TaskType.Image | TaskType.Video) => {
    Router.replace({ query: { ...Router.query, mediaType } }, undefined, {
      shallow: true,
      scroll: false,
    });
  };

  const getMediaType = (isVideoTask: boolean) =>
    isVideoTask ? TaskType.Video : TaskType.Image;

  const uploadFileToBucket = (
    file: File,
    {
      uploadUrl,
      uploadHeaders,
    }: { uploadUrl: string; uploadHeaders: { [key: string]: string } }
  ): Promise<'success' | 'fail'> =>
    fetch(uploadUrl, {
      method: 'PUT',
      headers: uploadHeaders,
      body: file,
    })
      .then((res) => (res.status < 300 ? 'success' : 'fail'))
      .catch((err) => {
        console.log(err);
        return err;
      });

  const uploadMedia = async ({
    setTaskInfo,
    file,
    singleTask,
    bulkUpload,
  }: {
    setTaskInfo: (info: TaskInfo) => void;
    file: File | File[];
    singleTask?: PostTask;
    bulkUpload?: PostBulkUpload;
  }) => {
    let progress = 0;
    let fileProgress = 0;

    setTaskInfo({
      status: TaskStatus.Uploading,
      progress,
      fileProgress: `0/${(file as File[]).length}`,
    });

    if (bulkUpload) {
      return bulkUpload.taskList.map(async (task, i) =>
        uploadFileToBucket(file[i], task).then((res: 'success' | 'fail') => {
          progress = 100 / (file as File[]).length + progress;
          fileProgress += 1;
          setTaskInfo({
            status: TaskStatus.Uploading,
            progress,
            fileProgress: `${fileProgress}/${(file as File[]).length}`,
          });
          return res;
        })
      );
    }
    await uploadFileToBucket(file as File, singleTask!).then(() =>
      setTaskInfo({
        status: TaskStatus.Uploading,
        progress: 100,
        fileProgress: `${(file as File[]).length}/${(file as File[]).length}`,
      })
    );
  };

  const isTaskResult = (
    mediaResponse: TaskResult | BulkUpload
  ): mediaResponse is TaskResult => mediaResponse.bulkUploadId === undefined;

  const getMediaResponseOrSharing = async ({
    type,
    taskId,
    isVideo,
    hasWatermark,
    track,
    convertFn,
  }: {
    type: 'tasks' | 'sharings';
    taskId: string;
    isVideo: boolean;
    hasWatermark: boolean;
    track: (category, action, params?) => void;
    convertFn: (taskId: string, imageFormat: string) => Promise<void>;
  }): Promise<TaskResult | BulkUpload | Sharing> => {
    if (type === 'sharings') {
      return getSharing({ sharingId: taskId });
    }

    const mediaResponse = await getTaskResult({
      taskId,
      isVideo,
      hasWatermark,
    });

    if (isTaskResult(mediaResponse)) {
      return mediaResponse;
    }

    track('server_download', TaskStatus.Started);
    const notSupportedFormatTasks = mediaResponse.taskList.filter((task) =>
      formatsNotSupportedInBrowsers.includes(
        task.result.outputs[0]?.contentType as TaskMimeType
      )
    );
    if (notSupportedFormatTasks.length === 0) {
      return mediaResponse;
    }

    const tasksConversions = notSupportedFormatTasks.map(async (task) => {
      const convertedImages = task.result.outputs[0]?.convertedImages || [];
      if (
        convertedImages.some(
          ({ imageContentType }) =>
            imageContentType === defaultOutputForConversion
        )
      ) {
        return;
      }

      track('output_conversion', TaskStatus.Started, {
        mimeType: defaultOutputForConversion,
      });

      await convertFn(task.taskId, defaultOutputForConversion);

      track('server_download', TaskStatus.Completed);
      track('output_conversion', TaskStatus.Completed, {
        mimeType: defaultOutputForConversion,
      });
    });

    await Promise.all(tasksConversions);
    return getTaskResult({
      taskId,
      isVideo,
      hasWatermark,
    });
  };

  return {
    getMediaResponseOrSharing,
    uploadMedia,
    getMediaType,
    setMediaTypeToUrl,
    cancelVideoTask,
    isTaskCompleted,
    exportTask,
    addMediasToBulkUpload,
    deleteTasks,
    reProcessBulkUpload,
    processVideoTask,
    processBulkUpload,
    isMaxMediaQuantityReached,
    postBulkUpload,
    postVideoTask,
    isVideoTaskInFileList,
    isVideoTask,
    useUploader,
    getTaskResult,
    getFinalOutputFromConvertedImages,
    getBulkUpload,
    getFirstCompletedTask,
    loadImage,
  };
};

export type UploaderService = ReturnType<typeof createUploaderService>;

export default serviceSingletonGetter(
  Symbol('uploaderService'),
  createUploaderService
);
