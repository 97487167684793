import Script from 'next/script';

export default function OneTrustScript() {
  return (
    <>
      <Script
        type="text/javascript"
        src="https://cdn.cookielaw.org/consent/7535b0ed-8e0f-49da-b55c-09ffc985e129/OtAutoBlock.js"
      />
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script="7535b0ed-8e0f-49da-b55c-09ffc985e129"
      />
      <Script id="opta-non-wrapper-init">
        {`
          function OptanonWrapper() { }
        `}
      </Script>
    </>
  );
}
