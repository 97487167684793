import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useRouter } from 'next/router';
import {
  trackCompletedEvent,
  trackFailedEvent,
} from '@/core/monitoring/trackEvents';
import maybeShowSuccessToast from '@/features/payment/maybeShowSuccessToast';
import * as Sentry from '@sentry/nextjs';
import { ErrorModal } from '@/features/payment/modals';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { pushModalSelector } from '@/stores/modalStore';
import { useUser } from '@/services/userService';
import { useMedia } from '@/services/mediaService';
import settingsState from '@/stores/settingsStore';
import { lockUISelector, unlockUISelector } from '@/stores/appStore';
import { useLocalization } from '@/services/localizationService';
import { useTracker } from '@/core/tracking';
import { usePaymentService } from '@/layout/appWrapper/ServiceProvider';
import { useConsent } from '@/features/userConsent';
import usePaymentStrategies from '@/features/payment/paymentStrategies/usePaymentStrategies';
import Spinner from '@/ui/content/Spinner';
import CountryNotAllowedModal from '@/features/payment/modals/CountryNotAllowedModal';

export function PayPalRedirectProvider({ children }: { children: ReactNode }) {
  const stripe = useStripe();
  const router = useRouter();
  const payTrackingInfo = useMemo(
    () => ({
      paymentPlatform: 'paypal',
      paymentStrategy: 'subscription',
    }),
    []
  );

  const lockUI = useSetRecoilState(lockUISelector);
  const unlockUI = useSetRecoilState(unlockUISelector);
  const pushModal = useSetRecoilState(pushModalSelector);
  const { mutate: mutateUser } = useUser();
  const { getMediaAndUpdateStore } = useMedia();
  const settings = useRecoilValue(settingsState);
  const { t, data } = useLocalization();
  const { track } = useTracker();
  const { trackPayment } = usePaymentService();
  const { shouldShowMarketingConsentModal } = useConsent();
  const { createStripeSubscription } = usePaymentService();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [hasCreatedSubscription, setHasCreatedSubscription] =
    useState<boolean>(false);

  const {
    emailReminderEnabled,
    freeTrialEnabled,
    redirect_status,
    paymentStrategyId,
    priceId,
    setup_intent_client_secret,
    taskId,
    willDownloadAutomatically,
    withIntroPrice,
    unlockedExports,
  } = router.query;
  const paramsToRemove = useMemo(
    () => [
      'redirect_status',
      'setup_intent_client_secret',
      'setup_intent',
      'priceId',
      'emailReminderEnabled',
      'freeTrialEnabled',
      'paymentStrategyId',
      'willDownloadAutomatically',
      'withIntroPrice',
      'unlockedExports',
    ],
    []
  );

  const paymentStrategies = usePaymentStrategies({
    selectedPeriod: 'weekly',
    remotePaymentStrategies: settings.values.paymentStrategies,
    isUpgrade: false,
    userPricePeriod: settings.values.highlightedOtpStrategy,
  });
  const selectedPaymentStrategy = useMemo(
    () =>
      paymentStrategies.find((strategy) => strategy.id === paymentStrategyId)!,
    [paymentStrategies, paymentStrategyId]
  );

  const removeRedirectQueryParameters = useCallback(() => {
    // Create a new query object excluding the parameters to remove
    const newQuery = { ...router.query };
    paramsToRemove.forEach((param) => delete newQuery[param]);

    // Update the URL with the new query object
    router.replace(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true }
    ); // shallow: true prevents a page reload
  }, [paramsToRemove, router]);

  function stringToBoolean(value: string): boolean {
    return (
      value.toLowerCase() === 'true' ||
      value === '1' ||
      value.toLowerCase() === 'yes'
    );
  }

  const onPaymentRedirect = useCallback(async () => {
    if (!stripe || isProcessing) {
      return;
    }

    setIsProcessing(true);
    lockUI({});

    let showSuccessToast = true;

    try {
      try {
        if (hasCreatedSubscription) {
          unlockUI({});
          setIsProcessing(false);
          return;
        }
        const setupIntentResult = await stripe.retrieveSetupIntent(
          String(setup_intent_client_secret)
        );
        if (setupIntentResult.error) {
          throw new Error();
        }

        const paymentMethodId = setupIntentResult.setupIntent
          .payment_method as string;

        const result = await createStripeSubscription(
          paymentMethodId,
          String(priceId),
          stringToBoolean(freeTrialEnabled as string),
          stringToBoolean(withIntroPrice as string),
          Number(unlockedExports)
        );

        setHasCreatedSubscription(true);

        if (result.status === 'incomplete') {
          showSuccessToast = false;
          pushModal({
            id: 'creationError',
            Modal: ErrorModal,
            error: {
              message: t(data.account.incompleteSubscriptionCreatedMessage),
            },
          });
        }

        trackCompletedEvent(['payment'], payTrackingInfo);
      } catch (e) {
        trackFailedEvent(['payment'], {
          ...payTrackingInfo,
          error: e.toString(),
        });
        throw e;
      }

      trackPayment({
        track,
        status: 'completed',
        paymentPlatform: 'paypal',
        priceId: String(priceId),
        freeTrialEnabled: stringToBoolean(freeTrialEnabled as string),
        emailReminderEnabled: stringToBoolean(emailReminderEnabled as string),
        paymentStrategy: 'subscription',
      });

      await mutateUser();

      if (taskId) {
        await getMediaAndUpdateStore();
      }

      if (showSuccessToast) {
        maybeShowSuccessToast({
          selectedPaymentStrategy,
          willDownloadAutomatically: stringToBoolean(
            willDownloadAutomatically as string
          ),
          settings,
          pushModal,
          shouldShowMarketingConsentModal,
          message: data.onboarding.congratsMember,
        });
      }
    } catch (e) {
      Sentry.captureException(e);

      if (e.status === 403 && e.message.startsWith('Web subscriptions')) {
        pushModal({
          id: 'forbiddenCountryError',
          Modal: CountryNotAllowedModal,
        });
      } else {
        pushModal({ id: 'paymentError', Modal: ErrorModal, error: e });
      }
    }

    unlockUI({});
    setIsProcessing(false);
    removeRedirectQueryParameters();
  }, [
    createStripeSubscription,
    data.account.incompleteSubscriptionCreatedMessage,
    data.onboarding.congratsMember,
    emailReminderEnabled,
    freeTrialEnabled,
    getMediaAndUpdateStore,
    hasCreatedSubscription,
    isProcessing,
    lockUI,
    mutateUser,
    payTrackingInfo,
    priceId,
    pushModal,
    removeRedirectQueryParameters,
    selectedPaymentStrategy,
    settings,
    setup_intent_client_secret,
    shouldShowMarketingConsentModal,
    stripe,
    t,
    taskId,
    track,
    trackPayment,
    unlockUI,
    unlockedExports,
    willDownloadAutomatically,
    withIntroPrice,
  ]);

  useEffect(() => {
    if (redirect_status === 'failed') {
      removeRedirectQueryParameters();
      pushModal({
        id: 'paymentError',
        Modal: ErrorModal,
        error: { message: 'PayPal setup failed' },
      });
    }

    if (!stripe) {
      return;
    }

    if (redirect_status === 'succeeded' && !isProcessing) {
      onPaymentRedirect();
    }
  }, [
    isProcessing,
    onPaymentRedirect,
    pushModal,
    redirect_status,
    removeRedirectQueryParameters,
    stripe,
  ]);

  return (
    <>
      {children}
      {isProcessing && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <Spinner color="white" size={80} />
        </div>
      )}
    </>
  );
}
