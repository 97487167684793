import { HttpService } from '@/core/http/httpService';
import { safeLocalStorage } from '@/core/safeLocalStorage/safeLocalStorage';
import { serviceSingletonGetter } from '@/services/serviceGetter';
import { FirebaseError } from 'firebase/app';
import { Preferences } from '../preferenceService';
import { TokenState } from './authTypes';

export const createAuthService = ({ post, get }: HttpService) => {
  const resetTokenInLocalStorage = (isFullReset = false) => {
    if (isFullReset) {
      safeLocalStorage.removeItem('user:reset');
    }

    // The stored data may be defective
    safeLocalStorage.removeItem('user:token');
    // Avoid an infinite reload loop
    safeLocalStorage.setItem('user:reset', JSON.stringify(true));
    window.location.reload();
  };

  const isLoginNotSupportedError = (error) =>
    error.code === 'auth/web-storage-unsupported';

  const isAccountLinkingNeededError = (error): error is FirebaseError =>
    error?.code === 'auth/account-exists-with-different-credential';

  const loginAsGuest = async (): Promise<TokenState> => {
    const user = await post(`/v1/web/users`);
    return {
      token: user.accessToken,
      isGuestToken: true,
    };
  };

  const loginWithAccount = async (
    firebaseIdToken: string,
    preferences: Preferences
  ): Promise<any> =>
    post('/v1/web/users/login', {
      firebaseIdToken,
      preferences,
    });

  const getUserInformation = async () => get('/v1/web/users/@me');

  return {
    resetTokenInLocalStorage,
    isLoginNotSupportedError,
    loginAsGuest,
    isAccountLinkingNeededError,
    loginWithAccount,
    getUserInformation,
  };
};

export type AuthService = ReturnType<typeof createAuthService>;

export default serviceSingletonGetter(Symbol('authService'), createAuthService);
