import { safeLocalStorage } from '@/core/safeLocalStorage/safeLocalStorage';
import {
  useAuthService,
  usePreferencesService,
} from '@/layout/appWrapper/ServiceProvider';
import { useUser } from '@/services/userService';
import { authState } from '@/stores/authStore';
import { getAuth, signOut } from 'firebase/auth';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

export default function useLoginCallbacks() {
  const router = useRouter();
  const setToken = useSetRecoilState(authState);
  const { getDefaultPreferences } = usePreferencesService();
  const { mutate: mutateUser } = useUser();
  const { loginWithAccount, loginAsGuest } = useAuthService();

  const login = useCallback(
    async (firebaseIdToken: string) => {
      const preferences = getDefaultPreferences();
      const user = await loginWithAccount(firebaseIdToken, preferences);
      setToken({ token: user.accessToken, isGuestToken: false });
      await mutateUser();
    },
    [getDefaultPreferences, loginWithAccount, setToken, mutateUser]
  );

  const logout = useCallback(async () => {
    // TODO remove after closing auto advance experiment
    // to avoid showing the auto advance after logout
    safeLocalStorage.setItem('autoAdvance:shown', 'true');

    const guestToken = await loginAsGuest();
    setToken(guestToken);
    const auth = getAuth();
    await signOut(auth);
    const { pathname } = router;
    delete router.query.mediaType;
    delete router.query.taskId;
    await router.push({
      pathname: '/',
      query: router.query,
    });
    if (pathname === '/result') router.reload();
  }, [loginAsGuest, router, setToken]);

  return useMemo(() => [login, logout] as const, [login, logout]);
}
